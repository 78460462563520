var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"refModalAssetList",attrs:{"id":"modal-asset-list","size":"lg","title":_vm.t('Danh sách tài sản'),"ok-title":_vm.t('Chọn'),"cancel-title":_vm.t('Hủy'),"cancel-variant":"outline-secondary","no-close-on-backdrop":"","no-close-on-esc":"","ok-disabled":!_vm.selectedRows.length},on:{"show":_vm.fetchAssets,"ok":_vm.onSelectAssets}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('vue-good-table',{attrs:{"style-class":"vgt-table striped bordered","mode":"remote","total-rows":_vm.totalRecords,"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"search-options":{
          enabled: false,
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        },"pagination-options":{
          enabled: true,
          perPage: _vm.serverParams.perPage,
        }},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-selected-rows-change":_vm.selectionChanged,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'code')?_c('span',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-asset-detail",modifiers:{"modal-asset-detail":true}}],staticClass:"font-weight-bold",on:{"click":function($event){return _vm.onEditItem(props.row)}}},[_vm._v(" "+_vm._s(props.row.code)+" ")])],1):(props.column.field === 'price')?_c('span',[_vm._v(" "+_vm._s(Number(props.row.price).toLocaleString())+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('Hiển thị tối đa'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '20', '50', '100', '500']},on:{"input":(value) =>
                    props.perPageChanged({ currentPerPage: value })},model:{value:(_vm.serverParams.perPage),callback:function ($$v) {_vm.$set(_vm.serverParams, "perPage", $$v)},expression:"serverParams.perPage"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.t('trên tổng số'))+" "+_vm._s(props.total)+" "+_vm._s(_vm.t('kết quả'))+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.serverParams.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":(value) => props.pageChanged({ currentPage: value })},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }